const messages = 
{
    en: {
        text: {
            welcome: 'welcome',
            locale_en: 'English',
            locale_de: 'Deutsch',
            locale_pl: 'Polski',
            locale_hu: 'Magyar',
            forgotpassword: 'Forgot password?',
            email: 'Email',
            password: 'Password',
            login: 'Login',
            register: 'Register',
            needmoresupport: 'Need more support?',
            needmoresupport2: 'If you have any questions, don\'t hesitate to call us or drop a mail.',
            or: 'or',
            userdetails: 'User details',
            companydetails: 'Company details',
            name: 'Name',
            title: 'Title',
            mr: 'Mr.',
            ms: 'Ms.',
            jobtitle: 'Job title',
            phonenumber: 'Phone number',
            country: 'Country',
            zip: 'Postal code',
            city: 'City',
            address: 'Address',
            vat: 'VAT number',
            success: 'Success',
            error: 'Error',
            registration: 'Thank you for your registration. We\'ll notify you of the approval of your registration by email.',
            resetpassword: 'Reset password',
            orders: 'Orders',
            neworder: 'New order',
            profile: 'Profile',
            logout: 'Logout',
            inprogress: 'In progress',
            done: 'Done',
            nodata: 'No data',
            orderdate: 'Order date',
            status: 'Status',
            project: 'Project',
            ponumber: 'PO Number',
            quantity: 'Quantity',
            unitprice: 'Unit price',
            totalprice: 'Total price',
            deadline: 'Deadline',
            files: 'Files',
            date: 'Date',
            filename: 'Filename',
            type: 'Type',
            modrequired: 'Modification Required',
            acceptdesign: 'Accept design',
            close: 'Close',
            expired: 'Expired',
            active: 'Active',
            folderphoto: 'photo',
            folderreport: 'report',
            folderadministrative: 'administrative',
            folderdesign: 'design',
            folderbom: 'bom',
            status0010: 'Missing data',
            status0100: 'New order',
            status0200: 'Under designing',
            status0220: 'Waiting for Design Acceptance',
            status0300: 'Design rejected',
            status0350: 'Design accepted',
            status0700: 'In Production',
            status0800: 'Ready',
            status0900: 'Waiting for response',
            designaccepted: 'Design accepted',
            designacceptedbody: 'Thank you, we will notify you by email when the product is ready',
            designrejected: 'Design rejected',
            designrejectedbody: 'Our designer team will contact you soon. Shortly you\'ll receive an email, please reply to it with your objections.',
            ordersuccess: 'Order successful. Please check your mailbox',
            quotationsuccess: 'Quotation request successful. Please check your mailbox',
            repeatpassword: 'Repeat Password',
            changepassword: 'Change password',
            warning: 'Warning',
            invalidfields: 'Some of the required fields are invalid. Please confirm the fields and submit it again.',
            passwordchangesuccess: 'Password change successful',
            projectname: 'Project name',
            supporttype: 'Support type',
            machinetype: 'Machine type',
            side: 'Side',
            vacuum: 'Vacuum',
            vacuumtype: 'Vacuum connection type',
            requestquote: 'Request quote',
            placeorder: 'Place order',
            ordermemoplaceholder: 'Pin in Paste, etc.',
            ordermemo: 'Additional note / special requirements',
            designformat: 'Design format',
            otherfiles: 'Other files',
            directionfiles: 'Picture for process direction files',
            drawingfiles: 'Multiboard drawing files',
            stencilfiles: 'Gerber / stencil files',
            bladelength: 'Squegee Blade Length',
            pcbthickness: 'PCB Thickness',
            smtheight: 'Max SMT Component height',
            contentofid: 'Content of identifier',
            identificationtype: 'Identification type',
            anodizingtype: 'Type of anodizing',
            supportedprinters: 'Supported printers',
            engraving: 'Engraving',
            gtnumberid: 'Use GT number as identification',
            confirm: 'Confirm',
            confirm1: 'By pressing the button, you accept that we have taken your order into account and we will immediately start designing the product. We will keep you informed about the status of the order. Thank you for your order!',
            accept: 'Accept',
            reject: 'Reject',
            back: 'Back',
            anodizingopt1: 'Normal',
            anodizingopt2: 'Black',
            idtypeopt1: 'DMC Code',
            idtypeopt2: 'QR Code',
            idtypeopt3: 'None',
            vacuumopt1: 'Yes',
            vacuumopt2: 'No',
            pleasewait: 'Please wait',
            customer: 'Customer',
            forgotpasswordsuccess: 'We\'ll send you a new password, please check your mailbox in a few minutes.',
            products: {fps: 'FPS', fpsdedicated: 'FPS dedicated' },
        },
        error: {
            passwordrequired: 'Password is required',
            passwordinvalid: 'Password must be at least 8 characters long',
            phonenumberrequired: 'Phone number is required',
            emailrequired: 'Email is required',
            emailinvalid: 'Invalid email',
            accessdenied: 'Access denied',
            loginfailed: 'Login failed, please check your credentials and try again. If you have forgotten your password, please request a reminder.',
            ziprequired: 'Postal code is required',
            titlerequired: 'Title is required',
            jobtitlerequired: 'Job title is required',
            cityrequired: 'City is required',
            countryrequired: 'Country is required',
            addressrequired: 'Address is required',
            namerequired: 'Name is required',
            vatrequired: 'VAT number is required',
            registration: 'An error occurred during registration! Please try again later or contact support',
            internal: 'Internal error. Please try again later or contact customer support',
            passworddifferent: 'Two password is different',
            projectrequired: 'Project name is required',
            vacuumrequired: 'Vacuum is required',
            vacuumtyperequired: 'Vacuum connection type is required',
            anodizingrequired: 'Type of anodizing is required',
            siderequired: 'Side is required',
            engravingrequired: 'Engraving is required',
            idtyperequired: 'Identification type is required',
            idtextrequired: 'Identification text is required',
            smtheightrequired: 'SMT Component height is required',
            pcthicknessrequired: 'PCB Thickness is required',
            pcbthicknessnumeric: 'PCB Thickness must be a numeric value',
            pcbthicknessminvalue: 'PCB Thickness must be 0.1 or greater',
            bladelengthrequired: 'Squegee Blade length is required',
            bladelengthnumeric: 'Squegee Blade length must be a numeric value',
            bladelengthminvalue: 'Squegee Blade length must be 0.1 or greater',
            quantityrequired: 'Quantity is required',
            quantitynumeric: 'Quantity must be a numeric value',
            quantityminvalue: 'Quantity must be 1 or greater',
            stencilfilesrequired: 'Gerber / Stencil files is required',
            drawingfilesrequired: 'Multiboard drawing files is required',
            directionfilesrequired: 'Picture for process direction files is required',
            designformatrequired: 'At least one design format is required',
            supporttyperequired: 'Support type is required',
            machinetyperequired: 'Machine type is required',
            unknownuser: 'Unknown E-mail',
        }
    },

    de: {
      text: {
          welcome: 'Willkommen',
          locale_en: 'English',
          locale_de: 'Deutsch',
          locale_pl: 'Polski',
          locale_hu: 'Magyar',
          forgotpassword: 'Passwort vergessen?',
          email: 'Email',
          password: 'Passwort',
          login: 'Anmeldung',
          register: 'Registrieren',
          needmoresupport: 'Brauchen Sie mehr Unterstützung?',
          needmoresupport2: 'Wenn Sie Fragen haben, zögern Sie nicht, uns anzurufen oder eine E-Mail zu schreiben.',
          or: 'oder',
          userdetails: 'Angaben zum Benutzer',
          companydetails: 'Angaben zum Unternehmen',
          name: 'Nam',
          title: 'Titel',
          mr: 'Herr',
          ms: 'Frau',
          jobtitle: 'Berufsbezeichnung',
          phonenumber: 'Telefonnummer',
          country: 'Land',
          zip: 'Postleitzahl',
          city: 'Stadt',
          address: 'Adresse',
          vat: 'Steuernummer',
          success: 'Erfolg',
          error: 'Fehler',
          registration: 'Vielen Dank für Ihre Anmeldung. Wir werden Sie per E-Mail über die Genehmigung Ihrer Anmeldung benachrichtigen.',
          resetpassword: 'Passwort zurücksetzen',
          orders: 'Bestellungen',
          neworder: 'Neue Bestellung',
          profile: 'Profil',
          logout: 'Abmeldung',
          inprogress: 'Laufend',
          done: 'Abgeschlossen',
          nodata: 'Keine daten',
          orderdate: 'Datum der bestellung',
          status: 'Status',
          project: 'Projekt',
          ponumber: 'PO Nummer',
          quantity: 'Anzahl',
          unitprice: 'Preis pro Einheit',
          totalprice: 'Gesamtpreis',
          deadline: 'Termin',
          files: 'Dateien',
          date: 'Datum',
          filename: 'Dateiname',
          type: 'Typ',
          modrequired: 'Änderung erforderlich',
          acceptdesign: 'Entwurf akzeptieren',
          close: 'Schließen',
          expired: 'Abgelaufen',
          active: 'Aktiv',
          folderphoto: 'bild',
          folderreport: 'bericht',
          folderadministrative: 'administrativ',
          folderdesign: 'entwurf',
          folderbom: 'bom',
          status0010: 'Fehlende Daten',
          status0100: 'Neue Bestellung',
          status0200: 'Under designing',
          status0220: 'Warten auf die Entwurfsabnahme',
          status0300: 'Entwurf abgelehnt',
          status0350: 'Entwurf angenommen',
          status0700: 'In Produktion',
          status0800: 'Abgeschlossen',
          status0900: 'Warten auf Antwort',
          designaccepted: 'Entwurf angenommen',
          designacceptedbody: 'Thank you, we will notify you by email when the product is ready',
          designrejected: 'Entwurf abgelehnt',
          designrejectedbody: 'Our designer team will contact you soon. Shortly you\'ll receive an email, please reply to it with your objections.',
          ordersuccess: 'Bestellung erfolgreich. Bitte prüfen Sie Ihre Mailbox',
          quotationsuccess: 'Angebotsanfrage erfolgreich. Bitte prüfen Sie Ihre Mailbox',
          repeatpassword: 'Passwort wiederholen',
          changepassword: 'Passwort ändern',
          warning: 'Warnung',
          invalidfields: 'Einige der erforderlichen Felder sind ungültig. Bitte bestätigen Sie die Felder und senden Sie sie erneut ab.',
          passwordchangesuccess: 'Passwortänderung erfolgreich',
          projectname: 'Projektname',
          supporttype: 'Supporttyp',
          machinetype: 'Typ der Maschine',
          side: 'Side',
          vacuum: 'Vakuum',
          vacuumtype: 'Typ des Vakuumanschlusses',
          requestquote: 'Angebot anfordern',
          placeorder: 'Bestellung aufgeben',
          ordermemoplaceholder: 'Pin in Paste, etc.',
          ordermemo: 'Additional note / special requirements',
          designformat: 'Entwurfsformat',
          otherfiles: 'Andere Dateien',
          directionfiles: 'Picture for process direction files',
          drawingfiles: 'Multiboard drawing files',
          stencilfiles: 'Gerber / stencil files',
          bladelength: 'Squegee Blade Length',
          pcbthickness: 'PCB Thickness',
          smtheight: 'Max SMT Component height',
          contentofid: 'Content of identifier',
          identificationtype: 'Art der Identifizierung',
          anodizingtype: 'Art der Eloxierung',
          supportedprinters: 'Unterstützte Drucker',
          engraving: 'Gravur',
          gtnumberid: 'GT-Nummer als Identifizierungstext verwenden',
          confirm: 'Bestätigen Sie',
          confirm1: 'Mit dem Drücken des Knopfes akzeptieren Sie, dass wir Ihre Bestellung berücksichtigt haben, und wir werden sofort mit der Gestaltung des Produkts beginnen. Wir werden Sie über den Status der Bestellung auf dem Laufenden halten. Vielen Dank für Ihre Bestellung!',
          accept: 'Annehmen',
          reject: 'Ablehnen',
          back: 'Back',
          anodizingopt1: 'Normal',
          anodizingopt2: 'Schwarz',
          idtypeopt1: 'DMC Code',
          idtypeopt2: 'QR Code',
          idtypeopt3: 'Keine',
          vacuumopt1: 'Ja',
          vacuumopt2: 'Nein',
          pleasewait: 'Bitte warten',
          customer: 'Kunde',
          forgotpasswordsuccess: 'Wir schicken Ihnen ein neues Passwort zu, bitte prüfen Sie in ein paar Minuten Ihre Mailbox.',
          products: {fps: 'FPS', fpsdedicated: 'FPS dedicated' },
        },
      error: {
          passwordrequired: 'Passwort ist erforderlich',
          passwordinvalid: 'Das Passwort muss mindestens 8 Zeichen lang sein.',
          phonenumberrequired: 'Telefonnummer ist erforderlich',
          emailrequired: 'E-mail ist erforderlich',
          emailinvalid: 'Ungültige E-Mail',
          accessdenied: 'Zugang verweigert',
          loginfailed: 'Login fehlgeschlagen. Bitte überprüfen Sie Ihre Anmeldedaten und versuchen Sie es erneut. Wenn Sie Ihr Passwort vergessen haben, fordern Sie bitte eine Erinnerung an.',
          ziprequired: 'Postleitzahl ist erforderlich',
          titlerequired: 'Titel ist erforderlich',
          jobtitlerequired: 'Berufsbezeichnung ist erforderlich',
          cityrequired: 'Stadt ist erforderlich',
          countryrequired: 'Land ist erforderlich',
          addressrequired: 'Adresse ist erforderlich',
          namerequired: 'Name ist erforderlich',
          vatrequired: 'Steuernummer ist erforderlich',
          registration: 'Bei der Registrierung ist ein Fehler aufgetreten! Bitte versuchen Sie es später noch einmal oder kontaktieren Sie den Support',
          internal: 'Netzwerkfehler. Bitte versuchen Sie es später noch einmal oder wenden Sie sich an den Kundensupport.',
          passworddifferent: 'Zwei Passwörter sind unterschiedlich',
          projectrequired: 'Projektname ist erforderlich',
          vacuumrequired: 'Vakuum ist erforderlich',
          vacuumtyperequired: 'Vacuum connection type is required',
          anodizingrequired: 'Type of anodizing is required',
          siderequired: 'Side is required',
          engravingrequired: 'Gravur ist erforderlich',
          idtyperequired: 'Identification type is required',
          idtextrequired: 'Identification text is required',
          smtheightrequired: 'SMT Component height is required',
          pcthicknessrequired: 'PCB Thickness is required',
          pcbthicknessnumeric: 'PCB Thickness must be a numeric value',
          pcbthicknessminvalue: 'PCB Thickness must be 0.1 or greater',
          bladelengthrequired: 'Squegee Blade length is required',
          bladelengthnumeric: 'Squegee Blade length must be a numeric value',
          bladelengthminvalue: 'Squegee Blade length must be 0.1 or greater',
          quantityrequired: 'Anzahl ist erforderlich',
          quantitynumeric: 'Anzahl muss ein numerischer Wert sein',
          quantityminvalue: 'Anzahl muss 1 oder größer sein',
          stencilfilesrequired: 'Gerber / Stencil files ist erforderlich',
          drawingfilesrequired: 'Multiboard drawing files ist erforderlich',
          directionfilesrequired: 'Picture for process direction files is required',
          designformatrequired: 'Mindestens ein entwurfsformat ist erforderlich',
          unknownuser: 'E-Mail Adresse ist nicht registriert',
          supporttyperequired: 'Support type ist erforderlich',
          machinetyperequired: 'Maschinentyp ist erforderlich',
        }
  },

    pl: {
        text: {
            welcome: 'powitanie',
            locale_en: 'English',
            locale_de: 'Deutsch',
            locale_pl: 'Polski',
            locale_hu: 'Magyar',
            forgotpassword: 'Zapomniałeś hasła?',
            email: 'Email',
            password: 'Hasło',
            login: 'Logowanie',
            register: 'Rejestracja',
            needmoresupport: 'Potrzebujesz więcej wsparcia?',
            needmoresupport2: 'Jeśli masz jakieś pytania, nie wahaj się do nas zadzwonić lub wysłać e-mail',
            or: 'lub',
            userdetails: 'Szczegóły użytkownika',
            companydetails: 'Szczegóły firmy',
            name: 'Nazwa',
            title: 'Tytuł',
            mr: 'Pan',
            ms: 'Pani',
            jobtitle: 'Stanowisko',
            phonenumber: 'Numer telefonu',
            country: 'Kraj',
            zip: 'Kod pocztowy',
            city: 'Rozliczenie',
            address: 'Adres',
            vat: 'Numer podatkowy',
            success: 'Sukces',
            error: 'Błąd',
            registration: 'Dziękujemy za rejestrację. O zatwierdzeniu rejestracji powiadomimy Cię e-mailem.',
            resetpassword: 'Resetowanie hasła',
            orders: 'Zamówienia',
            neworder: 'Nowe zamówienie',
            profile: 'Profil',
            logout: 'Wylogowanie',
            inprogress: 'W toku',
            done: 'Ukończony',
            nodata: 'Brak danych',
            orderdate: 'Data zamówienia',
            status: 'Status',
            project: 'Projekt',
            ponumber: 'Numer PO',
            quantity: 'Ilość',
            unitprice: 'Cena jednostkowa',
            totalprice: 'Cena całkowita',
            deadline: 'Termin',
            files: 'Pliki',
            date: 'Data',
            filename: 'Nazwa pliku',
            type: 'Typ',
            modrequired: 'Wymagana modyfikacja',
            acceptdesign: 'Akceptuj plan',
            close: 'Blisko',
            expired: 'Wygasł',
            active: 'Aktywny',
            folderphoto: 'foto',
            folderreport: 'raport',
            folderadministrative: 'administracyjny',
            folderdesign: 'plan',
            folderbom: 'bom',
            status0010: 'Brakujące dane',
            status0100: 'Nowe zamówienie',
            status0200: 'Pod projektem',
            status0220: 'Oczekiwanie na przyjęcie planu',
            status0300: 'Projekt odrzucony',
            status0350: 'Plan przyjęty',
            status0700: 'W produkcji',
            status0800: 'Gotowy',
            status0900: 'Oczekiwanie na odpowiedź',
            designaccepted: 'Plan przyjęty',
            designacceptedbody: 'Dziękujemy, powiadomimy Cię e-mailem, gdy produkt będzie gotowy',
            designrejected: 'Plan odrzucony',
            designrejectedbody: 'Nasz zespół projektantów wkrótce się z Tobą skontaktuje. Wkrótce otrzymasz wiadomość e-mail, na którą odpowiedz, zgłaszając swoje zastrzeżenia.',
            ordersuccess: 'Zamówienie powiodło się. Sprawdź swoje konto e-mail.',
            quotationsuccess: 'Prośba o wycenę powiodła się. Sprawdź swoją skrzynkę e-mail',
            repeatpassword: 'Powtórz hasło',
            changepassword: 'Zmiana hasła',
            warning: 'Ostrzeżenie',
            invalidfields: 'Niektóre z wymaganych pól są nieprawidłowe. Potwierdź pola i prześlij je ponownie.',
            passwordchangesuccess: 'Zmiana hasła powiodła się',
            projectname: 'Nazwa projektu',
            supporttype: 'Support type',
            machinetype: 'Typ maszyny',
            side: 'Strona',
            vacuum: 'Próżnia',
            vacuumtype: 'Vacuum connection type',
            requestquote: 'Request quote',
            placeorder: 'zamówienie od',
            ordermemoplaceholder: 'Pin in Paste, etc.',
            ordermemo: 'Dodatkowe uwagi / specjalne wymagania',
            designformat: 'Format projektu',
            otherfiles: 'Inne pliki',
            directionfiles: 'Zdjęcie kierunku procesu',
            drawingfiles: 'Plik panelizacji',
            stencilfiles: 'Pliki gerber / stencil',
            bladelength: 'Szerokość rakli',
            pcbthickness: 'Grubość PCB',
            smtheight: 'Maksymalna wysokość komponentu SMT',
            contentofid: 'Zawartość identyfikatora',
            identificationtype: 'Identyfikacja',
            anodizingtype: 'Typ anodacji',
            supportedprinters: 'Obsługiwane drukarki',
            engraving: 'Grawerowanie',
            gtnumberid: 'Użyj numeru GT jako tekstu identyfikacyjnego',
            confirm: 'Potwierdzenie',
            confirm1: 'Naciskając przycisk, akceptujesz, że uwzględniliśmy Twoje zamówienie i natychmiast rozpoczniemy projektowanie produktu. Będziemy na bieżąco informować o statusie zamówienia. Dziękujemy za zamówienie!',
            accept: 'Akceptuj',
            reject: 'Odrzucić',
            back: 'Back',
            anodizingopt1: 'Normalny',
            anodizingopt2: 'Czarny',
            idtypeopt1: 'DMC Kod',
            idtypeopt2: 'QR Kod',
            idtypeopt3: 'Brak',
            vacuumopt1: 'Tak',
            vacuumopt2: 'Nie',
            pleasewait: 'Proszę czekać',
            customer: 'Klient',
            forgotpasswordsuccess: 'Wyślemy ci nowe hasło, sprawdź swoją skrzynkę pocztową za kilka minut.',
            products: {fps: 'FPS', fpsdedicated: 'FPS dedicated' },
        },
        error: {
            passwordrequired: 'Wymagane jest hasło',
            passwordinvalid: 'Hasło musi składać się z co najmniej 8 znaków',
            phonenumberrequired: 'Numer telefonu jest wymagany',
            emailrequired: 'Wymagany jest adres e-mail',
            emailinvalid: 'Nieprawidłowy adres e-mail',
            accessdenied: 'Odmowa dostępu',
            loginfailed: 'Logowanie nie powiodło się. Sprawdź swoje dane uwierzytelniające i spróbuj ponownie. Jeśli nie pamiętasz hasła, poproś o przypomnienie.',
            ziprequired: 'Wymagany jest kod pocztowy',
            titlerequired: 'Musisz podać swoje imię i nazwisko',
            jobtitlerequired: 'Wymagana jest nazwa stanowiska',
            cityrequired: 'Należy określić gminę',
            countryrequired: 'Wymagany jest kraj',
            addressrequired: 'Adres jest wymagany',
            namerequired: 'Nazwa jest wymagana',
            vatrequired: 'Wymagany numer podatkowy',
            registration: 'Wystąpił błąd podczas rejestracji! Spróbuj ponownie później lub skontaktuj się z pomocą techniczną.',
            internal: 'Błąd sieci. Spróbuj ponownie później lub skontaktuj się z obsługą klienta.',
            passworddifferent: 'Dwa hasła są różne',
            projectrequired: 'Nazwa projektu jest wymagana',
            vacuumrequired: 'Wymagana jest próżnia',
            vacuumtyperequired: 'Vacuum connection type is required',
            anodizingrequired: 'Określenie anodowania jest obowiązkowe',
            siderequired: 'Side is required',
            engravingrequired: 'Grawerowanie jest wymagane',
            idtyperequired: 'Wymagany jest typ identyfikacji',
            idtextrequired: 'Wymagany jest tekst identyfikacyjny',
            smtheightrequired: 'SMT Component height is required',
            pcthicknessrequired: 'Wymagana grubość płytki PCB',
            pcbthicknessnumeric: 'Grubość PCB musi być wartością numeryczną',
            pcbthicknessminvalue: 'Grubość PCB musi wynosić 0,1 lub więcej',
            bladelengthrequired: 'Squegee Blade length is required',
            bladelengthnumeric: 'Squegee Blade length must be a numeric value',
            bladelengthminvalue: 'Squegee Blade length must be 0.1 or greater',
            quantityrequired: 'Wymagana jest ilość',
            quantitynumeric: 'Ilość musi być wartością numeryczną',
            quantityminvalue: 'Ilość musi wynosić 1 lub więcej',
            stencilfilesrequired: 'Gerber / Stencil files is required',
            drawingfilesrequired: 'Multiboard drawing files is required',
            directionfilesrequired: 'Picture for process direction files is required',
            designformatrequired: 'Wymagany jest co najmniej jeden format planu',
            unknownuser: 'Adres e-mail nie jest zarejestrowany',
            supporttyperequired: 'Wymagany jest Support type',
            machinetyperequired: 'Wymagany jest typ maszyny',
        }
  },

      hu: {
        text: {
            welcome: 'üdvözlet',
            locale_en: 'English',
            locale_de: 'Deutsch',
            locale_pl: 'Polski',
            locale_hu: 'Magyar',
            forgotpassword: 'Elfelejtett jelszó',
            email: 'Email',
            password: 'Jelszó',
            login: 'Belépés',
            register: 'Regisztráció',
            needmoresupport: 'Segítségre van szüksége?',
            needmoresupport2: 'Ha bármilyen kérdése van, vegye fel velünk a kapcsolatot az alábbi elérhetőségeken.',
            or: 'vagy',
            userdetails: 'Felhasználó adatok',
            companydetails: 'Cég adatok',
            name: 'Név',
            title: 'Megszólítás',
            mr: 'Úr',
            ms: 'Hölgy',
            jobtitle: 'Munkakör',
            phonenumber: 'Telefonszám',
            country: 'Ország',
            zip: 'Irányítószám',
            city: 'Település',
            address: 'Cím',
            vat: 'Adószám',
            success: 'Siker',
            error: 'Hiba',
            registration: 'Köszönjük a regisztrációt. A regisztráció jóváhagyásáról e-mailben értesítjük Önt.',
            resetpassword: 'Új jelszó igénylése',
            orders: 'Rendelések',
            neworder: 'Új rendelés',
            profile: 'Profil',
            logout: 'Kilépés',
            inprogress: 'Folyamatban levő rendelések',
            done: 'Elkészült rendelések',
            nodata: 'Nincs adat',
            orderdate: 'Rendelés ideje',
            status: 'Státusz',
            project: 'Project',
            ponumber: 'Megrendelés szám',
            quantity: 'Mennyiség',
            unitprice: 'Egységár',
            totalprice: 'Teljes ár',
            deadline: 'Határidő',
            files: 'Dokumentumok',
            date: 'Dátum',
            filename: 'Dokumentum',
            type: 'Típus',
            modrequired: 'Terv módosítás szükséges',
            acceptdesign: 'Terv elfogadása',
            close: 'Bezár',
            expired: 'Lejárt',
            active: 'Aktív',
            folderphoto: 'fotó',
            folderreport: 'riport',
            folderadministrative: 'adminisztratív',
            folderdesign: 'terv',
            folderbom: 'bom',
            status0010: 'Hiányzó adat',
            status0100: 'Új rendelés',
            status0200: 'Tervezés alatt',
            status0220: 'Terv elfogadásra vár',
            status0300: 'Terv elutasítva',
            status0350: 'Terv elfogadva',
            status0700: 'Gyártás alatt',
            status0800: 'Kész',
            status0900: 'Válaszra vár',
            designaccepted: 'Terv elfogadva',
            designacceptedbody: 'Köszönjük, e-mailben értesítjük Önt, ha a termék elkészült.',
            designrejected: 'Terv elutasítva',
            designrejectedbody: 'Tervező csapatunk hamarosan felveszi Önnel a kapcsolatot. Rövidesen kap egy e-mailt, kérjük válaszoljon rá kifogásaival.',
            ordersuccess: 'Sikeres rendelés. Kérjük ellenőrizze postafiókját',
            quotationsuccess: 'Az árajánlatkérés sikeres. Kérjük ellenőrizze postafiókját',
            repeatpassword: 'Jelszó ismét',
            changepassword: 'Jelszó megváltoztatása',
            warning: 'Figyelem',
            invalidfields: 'Néhány kötelező mező érvénytelen. Kérjük, javítsa ki a mezőket, és küldje el újra.',
            passwordchangesuccess: 'Sikeres jelszó változtatás',
            projectname: 'Project neve',
            supporttype: 'Support type',
            machinetype: 'Gép típusa',
            side: 'Oldal',
            vacuum: 'Vákuum',
            vacuumtype: 'Vákuum connection type',
            requestquote: 'Árajánlat kérés',
            placeorder: 'Megrendel',
            ordermemoplaceholder: 'Pin in Paste, etc.',
            ordermemo: 'További megjegyzés / speciális kérések',
            designformat: 'Terv formátuma',
            otherfiles: 'Egyéb fájlok',
            directionfiles: 'Picture for process direction files',
            drawingfiles: 'Multiboard drawing files',
            stencilfiles: 'Gerber / stencil files',
            bladelength: 'Squegee Blade Length',
            pcbthickness: 'PCB vastagság',
            smtheight: 'Max SMT komponens magasság',
            contentofid: 'Content of identifier',
            identificationtype: 'Identification type',
            anodizingtype: 'Type of anodizing',
            supportedprinters: 'Támogatott nyomtatók',
            engraving: 'Gravírozás',
            gtnumberid: 'GT szám használata mint azonosító szöveg',
            confirm: 'Jóváhagyás',
            confirm1: 'A gomb megnyomásával elfogadja, hogy megrendelését figyelembe vettük, és azonnal megkezdjük a termék tervezését. A megrendelés állásáról folyamatosan tájékoztatni fogjuk Önt. Köszönjük megrendelését!',
            accept: 'Elfogad',
            reject: 'Elutasít',
            back: 'Vissza',
            anodizingopt1: 'Normál',
            anodizingopt2: 'Fekete',
            idtypeopt1: 'DMC Kód',
            idtypeopt2: 'QR Kód',
            idtypeopt3: 'Nincs',
            vacuumopt1: 'Igen',
            vacuumopt2: 'Nem',
            pleasewait: 'Kis türelmet',
            customer: 'Megrendelő',
            forgotpasswordsuccess: 'Küldünk egy új jelszót, kérjük ellenőrizze a postaládáját néhány percen belül.',
            products: {fps: 'FPS', fpsdedicated: 'FPS dedikált' },
        },
        error: {
            passwordrequired: 'Jelszó megadása kötelező',
            passwordinvalid: 'Jelszónak minimum 8 karakter hosszúnak kell lennie',
            phonenumberrequired: 'Telefonszám megadása kötelező',
            emailrequired: 'Email cím megadása kötelező',
            emailinvalid: 'Érvénytelen email cím',
            accessdenied: 'Hozzáférés megtagadva',
            loginfailed: 'A bejelentkezés sikertelen. Kérjük ellenőrizze a hitelesítő adatokat és próbálja meg újra. Ha elfelejtette jelszavát, kérjük kérjen emlékeztetőt.',
            ziprequired: 'Irányítószám megadása kötelező',
            titlerequired: 'Megszólítás megadása kötelező',
            jobtitlerequired: 'Munkakör megadása kötelező',
            cityrequired: 'Település megadása kötelező',
            countryrequired: 'Ország megadása kötelező',
            addressrequired: 'Cím megadása kötelező',
            namerequired: 'Név megadása kötelező',
            vatrequired: 'Adószám megadása kötelező',
            registration: 'Hiba történt a regisztráció során! Kérjük, próbálja meg később újra, vagy lépjen kapcsolatba az ügyfélszolgálattal',
            internal: 'Hálózati hiba. Próbálja újra, vagy vegye fel a kapcsolatot az ügyfélszolgálattal',
            passworddifferent: 'A megadott két jelszó nem egyezik',
            projectrequired: 'Project név megadása kötelező',
            vacuumrequired: 'Vákuum megadása kötelező',
            vacuumtyperequired: 'Vacuum connection type is required',
            anodizingrequired: 'Eloxálás megadása kötelező',
            siderequired: 'Oldal megadása kötelező',
            engravingrequired: 'Gravírozás megadása kötelező',
            idtyperequired: 'Azonosító típusának megadása kötelező',
            idtextrequired: 'Azonosító szöveg megadása kötelező',
            smtheightrequired: 'SMT komponens magasság megadása kötelező',
            pcthicknessrequired: 'PCB vastagság megadása kötelező',
            pcbthicknessnumeric: 'PCB vastagságnak számnak kell lennie',
            pcbthicknessminvalue: 'PCB vastagságnak 0.1 vagy nagyobbnak kell lennie',
            bladelengthrequired: 'Squegee Blade length is required',
            bladelengthnumeric: 'Squegee Blade length must be a numeric value',
            bladelengthminvalue: 'Squegee Blade length must be 0.1 or greater',
            quantityrequired: 'Mennyiség megadása kötelező',
            quantitynumeric: 'Mennyiségnek egész számnak kell lennie',
            quantityminvalue: 'Quantity must be 1 or greater',
            stencilfilesrequired: 'Gerber / Stencil files is required',
            drawingfilesrequired: 'Multiboard drawing files is required',
            directionfilesrequired: 'Picture for process direction files is required',
            designformatrequired: 'Legalább egy terv formátum megadása kötelező',
            unknownuser: 'E-mail cím nincs regisztrálva',
            supporttyperequired: 'Support type is required',
            machinetyperequired: 'Gép típusának megadása kötelező',
        }
        },
}

export default messages;