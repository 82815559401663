<template>
    <div :id="modalid" class="modal fade" data-bs-backdrop="static" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">{{ modaltitle }}</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    {{ modalbody }}
                </div>
                <div class="modal-footer">
                    <button type="button" v-for="button in buttons" class="btn" :class="button.class" :data-value="button.id">{{ button.caption }}</button>
                </div>
            </div>
        </div>
    </div>

</template>
<script>

export default
{
    props:
    {

    },
    data()
    {
        return { 
            modalid: 'modal',
            modaltitle: 'Title',
            modalbody: 'Body',
            buttons: []
        }
    },
    methods:
    {
        resetButtons()
        {
            this.buttons = [];
        },
        addButton(id, caption, cssclass='')
        {
            this.buttons.push({id: id, caption: caption, class: cssclass });
        },
        async waitModal()
        {
            await this.$nextTick();
            return new Promise((complete, failed) => 
            {
                const buttons = document.getElementById(this.modalid).querySelectorAll('button');
                const modal = new this.bootstrap.Modal('#' + this.modalid, {});
                modal.show();
                buttons.forEach((button) => { button.addEventListener('click', (event) => { modal.hide(); complete(event.target.dataset.value); }) });
            });
        }    

    }

}
</script>