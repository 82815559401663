import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import( '../views/home.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import( '../views/login.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/productselect',
    name: 'productselect',
    component: () => import( '../views/productselect.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/fps',
    name: 'fps',
    component: () => import( '../views/order_fps.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/fpsdedicated',
    name: 'fpsdedicated',
    component: () => import( '../views/order_fpsdedicated.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import( '../views/orders.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import( '../views/profile.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/register',
    name: 'register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/register.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/forgotpassword',
    name: 'forgotpassword',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/forgotpassword.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notfound',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/login.vue'),
    meta: { requiresAuth: false },
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
