import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createI18n } from 'vue-i18n'
import * as bootstrap from 'bootstrap/dist/js/bootstrap'
import API from './API.js'
import messages from './translations.js'
import datetimeFormats from './datetimeformats.js'

  const i18n = createI18n({
    locale: 'en', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
    datetimeFormats,
    // If you need to specify other options, you can set other options
    // ...
  });


const app = createApp(App);
app.use(router);
app.use(i18n);
app.config.globalProperties.API = API;
app.config.globalProperties.bootstrap = bootstrap;
app.mount('#app');

router.beforeEach(async (to, from) => {
    // ...
    // explicitly return false to cancel the navigation
    
    if (to.name == 'home' || to.name == 'notfound')
    {
        const name = app.config.globalProperties.API.isAuthenticated() ? 'orders' : 'login';
        return { name: name, force: true }
    }
    if (to.meta.requiresAuth)
    {
        if (!app.config.globalProperties.API.isAuthenticated() && to.name != 'login') return { name: 'login', force: true };

    } else
    {
        if (app.config.globalProperties.API.isAuthenticated()) return false;
    }
  });



