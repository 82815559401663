<template>
<header>
    <nav class="py-3">
        <div class="container">
            <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                <div class="d-flex flex-fill align-items-center mb-2 mb-lg-0 text-white text-decoration-none">
                    <router-link :to="{ name: 'home' }">
                        <img src="@/assets/img/grif_fps_logo.png">
                    </router-link>
                </div>

                <ul class="nav col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0 text-end">
                    <li v-if="$root.isAuthenticated"><router-link :to="{ name: 'orders' }" class="nav-link px-2" :class="[ isActive('orders') ? 'active' : 'text-white' ]">{{ $t('text.orders') }}</router-link></li>
                    <li v-if="$root.isAuthenticated && !$root.isAdmin()"><router-link :to="{ name: 'productselect' }" class="nav-link px-2" :class="[ isActive('productselect') ? 'active' : 'text-white' ]">{{ $t('text.neworder') }}</router-link></li>
                    <li v-if="$root.isAuthenticated && !$root.isAdmin()"><router-link :to="{ name: 'profile' }" class="nav-link px-2" :class="[ isActive('profile') ? 'active' : 'text-white' ]">{{ $t('text.profile') }}</router-link></li>
                    <li v-if="$root.isAuthenticated"><a href="#" class="nav-link px-2 text-white" @click.prevent="$root.logout()">{{ $t('text.logout') }}</a></li>
                    <li class="nav-item dropdown ws-language">
                        <a href="#" class="nav-link" data-bs-toggle="dropdown" aria-expanded="false" role="button"><img :alt="'sel_' + $i18n.locale" :src="require('@/assets/img/flags/' + $i18n.locale + '.svg')" class="ws-flag"></a>
                        <ul class="dropdown-menu">
                            <li v-for="(locale, index) in $i18n.availableLocales" class="dropdown-item" :class="{active: isActiveLocale(locale)}"><a href="#" @click.prevent="$i18n.locale=locale"><img :alt="locale" :src="require('@/assets/img/flags/' + locale + '.svg')" class="ws-flag me-2"><span>{{ $t('text.locale_' + locale) }}</span></a></li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
      <div class="ws-line my-4"></div>
  </header>

</template>

<script>

export default
{
    calculated:
    {
        routename()
        {
            return this.$route.name;
        }

    },
    methods:
    {
        isActiveLocale(locale)
        {
            return locale == this.$i18n.locale;
        },
        isActive(name)
        {
//            console.log('route', this.$route.name);
            return this.$route.name == name;
        },
    },
    async mounted() 
    {
//      await router.isReady()
//      console.log(this.$i18n.availableLocales);
//      console.log(navigator.language.substr(0, 2));
    }
}
</script>

