const API =
{
//  apiurl: 'http://www.api-griftools.local/',
//  apiurl: 'https://testmea-api.levels.hu/',
  apiurl: 'https://mea-api.griftools.hu/',
  method: 'POST',
  root: {},
  endpoint: () =>
  {
    return API.apiurl + 'wsapi';
  },
  request: async (command, data={}, hourglass=true) =>
  {

    if (API.getToken('token') == null) await API.requestToken();

    if (hourglass) API.root.loader = true;

    const formData = new FormData();
    formData.append('cmd', command);
    formData.append('token', API.getToken('token'));
    formData.append('auth', API.getToken('auth'));

    Object.entries(data).forEach(([key, value]) => 
    { 
      if (value instanceof FileList) for (const file of value) formData.append(key, file, file.name); else formData.append(key, value);
    });

    var requestOptions = {
      method: API.method,
      headers: {
        'Authorization': 'Bearer ' + API.getToken('token'),
        'Accept': 'application/json',
      },
      body: formData,
      credentials: 'include',
      }
    const response = await fetch(API.endpoint(), requestOptions);
    var rjson = {};
    if (response.ok)
    {
      rjson = await response.json();
      if (rjson.status != null && rjson.status == 5) API.logout();
//      console.log(rjson);
    } else rjson = { status: 256, message: 'Network error'};
    if (hourglass) API.root.loader = false;

    API.root.isAuthenticated = API.isAuthenticated();
    
    return rjson;

  },
  requestToken: async () => 
  {
    const formData = new FormData();
    formData.append('cmd', 'token');
    var requestOptions = {
      method: API.method,
      headers: {
        'Accept': 'application/json',
      },
      body: formData,
      credentials: 'include',
      }
    const response = await fetch(API.endpoint(), requestOptions);
    if (response.ok)
    {
      const rjson = await response.json();
      if (rjson['status'] != null && rjson['status'] == 0 && rjson['data']['token'] != null) API.setToken('token', rjson['data']['token']);
      if (rjson['status'] != null && rjson['status'] == 0 && rjson['data']['auth'] != null) API.setToken('auth', rjson['data']['auth']);
    }
  },
  getToken: (name) => { return localStorage.getItem(name); },
  setToken: (name, token) => { localStorage.setItem(name, token); },
  getLoginData: async () => 
  { 
    var resp = await API.request('getlogindata');
    if (resp.status != null)
    {
      if (resp.status == 0) API.logindata = resp.data.logindata;
      if (resp.status == 5) 
      {
        API.setToken('auth', '');
        API.root.$router.replace({ name: 'home' });
      }

    }  
  },
  isAuthenticated: () => { return (API.getToken('auth') != null && (API.getToken('auth').length == 32 || API.getToken('auth').length == 64)); },
  auth: (username, password) => { resp = API.request('auth', {username: username, password: password }); },
  logout: async () => 
  { 
    var resp = await API.request('logout'); 
    if (resp.status != null && resp.status == 0 && resp.data.token != null) API.setToken('token', resp.data.token);
    API.setToken('auth', '');
    API.root.isAuthenticated = false;
    API.root.$router.replace({ name: 'home' });
  }
}

export default API;
