<template>
  <div class="d-flex min-vw-100 min-vh-100 flex-column">
  <ws_header/>

  <main class="flex-fill d-flex flex-column">
    <router-view/>
  </main>


  <ws_footer/>

  <div v-if="loader" class="modal-backdrop"><div><span class="loader"></span><span class="mt-2 fs-3 text-white text-uppercase">{{ $t('text.pleasewait') }}</span></div></div>

  <div id="toast-container"></div>
   <div id="modal-container"><Modal ref="modal"/></div>

  </div>   
  
</template>

<script>
import ws_header from './components/header.vue'
import ws_footer from './components/footer.vue'

import UI from './UI.js'
import Modal from '@/components/vue-modal.vue'

export default 
{
  props:
  {
    menu: Number,
  },
  data()
  {
    return {
      isAuthenticated: false,
      instance: null,
      loader: false,
    }
  },
  computed:
  {
  },
  name: 'App',
  components: 
  {
    ws_header,
    ws_footer,
    Modal,
  },
  methods:
  {
    isAdmin()
    {
      return this.API.getToken('auth').length == 64;
    },
    async modal(title, text, buttons)
    {
      this.$refs.modal.modaltitle = title;
      this.$refs.modal.modalbody = text;
      this.$refs.modal.resetButtons();
      buttons.forEach((elem) =>
      {
        this.$refs.modal.addButton(elem.value, elem.caption, elem.class);

      });
      return await this.$refs.modal.waitModal();
    },
    async alert(title, text)
    {
      this.$refs.modal.modaltitle = title;
      this.$refs.modal.modalbody = text;
      this.$refs.modal.resetButtons();
      this.$refs.modal.addButton('1', 'OK', 'btn-gt-primary');
      return await this.$refs.modal.waitModal();
    },
    async confirm(text, title='Confirm')
    {
      this.$refs.modal.modaltitle = title;
      this.$refs.modal.modalbody = text;
      this.$refs.modal.resetButtons();
      this.$refs.modal.addButton('1', 'Yes', 'btn-gt-primary');
      this.$refs.modal.addButton('0', 'No', 'btn-gt-primary');
      return (await this.$refs.modal.waitModal() == '1');
    },
    async logout()
    {
      await this.API.logout();
      await this.$nextTick();
      this.$router.replace({ name: 'home' });
    },

  },
  async created()
  {
    var locale = navigator.language.substr(0, 2).toLowerCase();
    if (this.$i18n.availableLocales.includes(locale)) this.$i18n.locale = locale;
    this.API.root = this;
  },

}

</script>

<style lang="scss">
  @import "../scss/custom.scss";
  @import "../scss/style.scss";
  @import'~bootstrap/dist/css/bootstrap.css'  
</style>
